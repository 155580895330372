import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import { withAuth } from '@okta/okta-react';

import config from 'utils/okta/okta.config.js';

import 'containers/login/okta-widget/okta-theme.css';

const OktaSignInWidget = () => {
  const widget = useRef();
  const containerRef = useRef(null);

  useEffect(() => {
    const el = containerRef.current;

    if (!el) return;

    widget.current = new OktaSignIn({
      baseUrl: config.url,
      clientId: config.clientId,
      redirectUri: config.redirectUri,
      authParams: {
        display: 'page',
        issuer: config.issuer,
        responseType: ['code'],
        pkce: true,
        scopes: ['openid', 'profile', 'email'],
      },
      features: {
        autoPush: true,
        rememberMe: true,
        registration: false,
        hideSignOutLinkInMFA: true,
        windowsVerify: true,
      },
      brandName: 'MARKETview',
      logo: null,
      i18n: {
        en: {
          'primaryauth.title': 'Log In',
          'primaryauth.submit': 'Sign In',
          autoPush: 'Send push automatically next time',
          'enroll.choices.description.generic':
            'MARKETview requires multifactor authentication to add an additional layer of security when signing in to your account.',
          'enroll.totp.setupApp':
            'Launch the {0} application on your mobile device and select Add an account.',
        },
      },
    });

    function successCallback() {}

    function errorCallback(err) {
      console.error('WIDGET ERROR', err);
    }

    widget.current.renderEl({ el }, successCallback, errorCallback);

    return () => {
      widget.current.remove();
    };
  }, []);

  return <div ref={containerRef} />;
};

export default withAuth(OktaSignInWidget);
